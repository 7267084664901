import React, { useState, useEffect } from "react"
import {
  setKey,
  setDefaults,
  setLanguage,
  setRegion,
  fromAddress,
  fromLatLng,
  fromPlaceId,
  setLocationType,
  geocode,
  RequestType,
} from "react-geocode";
import JobDataService from "services/JobDataService";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { faWalking, faCar } from "@fortawesome/free-solid-svg-icons"
import  "./jobboard.css";
import { useNavigate } from "react-router-dom";

// Function to calculate the distance using the Haversine formula
const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371e3; // meters
  const φ1 = lat1 * Math.PI / 180; // φ, λ in radians
  const φ2 = lat2 * Math.PI / 180;
  const Δφ = (lat2 - lat1) * Math.PI / 180;
  const Δλ = (lon2 - lon1) * Math.PI / 180;

  const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) *
    Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c; // in meters
};

const JobBoard = () => {
  const [jobs, setJobs] = useState([])
  const [workerLocation, setWorkerLocation] = useState(null)
  const [currentJob, setCurrentJob] = useState({
    jobTitle: "",
    jobDescription: "",
    jobExperience: "",
    jobType: "",
    provider: "",
    careHome: "",
    workerType: "",
    noofVacancies: "",
    startDate: "",
    startTime: "",
    endTime: "",
    endDate: "",
    enableRecurrence: "",
    breaks: "",
    jobstatus: "",
    jobID: "",
    category: "",
    featured: "",
  })
  const [searchCriteria, setSearchCriteria] = useState("")
  const [maxDistance, setMaxDistance] = useState(32.1869) // default 20 miles in km
  const [allJobs, setAllJobs] = useState([]) // State for all fetched jobs
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");

  setKey("AIzaSyC7RdiBANe6MImEXQLN7dSU5WrXKwz-wgs")

    const navigate = useNavigate()

    const navigateToJobDetails = jobID => {
      navigate(`/job-details/${jobID}`)
    }

      useEffect(() => 
      {
        if (localStorage.getItem("authUserDetails")) {
          let userDetails = JSON.parse(localStorage.getItem("authUserDetails"))

             const obj = JSON.parse(localStorage.getItem("authUserDetails"))

             console.log("myomy: " + obj);

          let address = userDetails.address

          if (address) setAddress(address);
          console.log("Address: " + address);
        } else {
          console.log("No user details found in localStorage.")
        }
      }, [])

  useEffect(() => {
    JobDataService.getAll(
      data => {
        setAllJobs(data)
        console.log("Job data: " + data);
      },
      [currentJob]
    )
    
    if (address) {
    fromAddress(address)
      .then(response => {
        const { lat, lng } = response.results[0].geometry.location
        setWorkerLocation({ lat, lng })
      })
      .catch(error => console.error("Geocoding error:", error))
    }
  }, [address])

  useEffect(() => {
    if (workerLocation && allJobs.length > 0) {
      const walkingSpeedKmH = 5 // Average walking speed in km/h
      const drivingSpeedKmH = 30 // Average driving speed in km/h

      const processedJobs = allJobs
        .map(job => {
          if (job.coordinates) {
            const [lat, lng] = job.coordinates.split(", ").map(Number)
            if (!isNaN(lat) && !isNaN(lng)) {
              // Calculate distance in kilometers
              const distanceInKm =
                calculateDistance(
                  workerLocation.lat,
                  workerLocation.lng,
                  lat,
                  lng
                ) / 1000 // Convert distance to kilometers

              // Calculate average commute times and create a new job object
              return {
                ...job,
                distance: distanceInKm,
                walkingTime: (distanceInKm / walkingSpeedKmH).toFixed(2), // Time in hours
                drivingTime: (distanceInKm / drivingSpeedKmH).toFixed(2), // Time in hours
              }
            }
          }
          return null
        })
        .filter(job => job && job.distance <= maxDistance) // Filter based on maxDistance

      setJobs(processedJobs)
    }
  }, [workerLocation, allJobs, maxDistance]) // Depends on workerLocation, allJobs, and maxDistance

   const handleDistanceChange = event => {
     setMaxDistance(Number(event.target.value))
   }


  return (
    <div className="page-content">
      <div className="distance-filter">
        <label>
          Max Distance:
          <input
            type="range"
            min="1" // e.g., 1 km
            max="80.4672" // e.g., 1000 km
            value={maxDistance}
            onChange={handleDistanceChange}
          />
          {maxDistance.toFixed(2)} km
        </label>
      </div>
      <div className="job-board-header">
        <h1>
          Job Listing{jobs.length !== 1 ? "s" : ""} near {address}
        </h1>
        {searchCriteria && <p>Search Criteria: {searchCriteria}</p>}
        <p>
          Showing {jobs.length} job{jobs.length !== 1 ? "s" : ""}
        </p>
      </div>
      {jobs.map(job => (
        <div key={job.key} className="job-card">
          <div className="job-header">
            <h2 className="job-title">{job.jobTitle}</h2>
            <button
              onClick={() => navigateToJobDetails(job.key)}
              className="apply-button"
            >
              View
            </button>
          </div>
          <div className="job-detail">
            <strong>Care Home:</strong> {job.careHome}
          </div>
          <div className="job-detail">
            <strong>Type:</strong> {job.jobType}
          </div>
          <div className="job-detail">
            <strong>Start Date:</strong> {job.startDate}
          </div>
          <div className="job-detail">
            <strong>Start Time:</strong> {job.startTime}
          </div>
          <div className="job-detail">
            <strong>Distance:</strong>{" "}
            {job.distance ? job.distance.toFixed(2) + " km" : "N/A"}
          </div>
          <div className="job-time walking-time">
            <strong>Walking:</strong>{" "}
            {job.walkingTime ? job.walkingTime + " hrs" : "N/A"}
          </div>
          <div className="job-time driving-time">
            <strong>Driving:</strong>{" "}
            {job.drivingTime ? job.drivingTime + " hrs" : "N/A"}
          </div>
        </div>
      ))}
    </div>
  )
};

export default JobBoard;
